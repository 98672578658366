import { isEmpty, unescape } from "lodash";
import { render as renderToString } from "@react-email/components";
import { NotificationEmails } from "../schemas";

export function coalesceToDefaultConfig(
  defaultEmailConfig: NotificationEmails.Type,
  customerEmailConfig: Partial<NotificationEmails.Type> | null,
  configValue: keyof NotificationEmails.Type,
  defaults?: boolean
) {
  if (!customerEmailConfig || !customerEmailConfig[configValue] || defaults) {
    return defaultEmailConfig[configValue];
  }
  return customerEmailConfig[configValue];
}

export function coalesceToTrue(
  customerEmailConfig: Partial<NotificationEmails.Type> | null,
  configValue: keyof NotificationEmails.Type,
  defaults?: boolean
): boolean {
  if (!customerEmailConfig || defaults) {
    return true;
  }
  return (customerEmailConfig[configValue] as boolean) ?? true;
}

export const emailCombineText = (text: string[]) => {
  return text.filter(t => !isEmpty(t)).join("");
};

export const emailCombine = (...input: string[]) => emailCombineText(input);

export const formatTemplateVariable = (str: string) => `<%- ${str} %>`;

export const capitalizeVariableString = (str: string) =>
  `<%- (${str}).charAt(0).toUpperCase() + (${str}).slice(1) %>`;

/** 1. Converting react email component to string with `render`
 *  2. Converting HTML entities to their correct characters with `unescape`
 *  3. Removing comments which might appear within customers emails and prevent tests from passing
 */
export const renderComponentToHtml = async (ele: JSX.Element) =>
  unescape(await renderToString(ele, { pretty: true })).replaceAll(/<!--.*?-->/g, "");
