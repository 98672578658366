import {
  createBasicDefaultEmailConfigPartial,
  createDefaultEmailConfig,
} from "@smartrr/shared/entities/CustomerEmailConfig";
import { OrganizationIntegrationId } from "@smartrr/shared/entities/Integration";
import type { RequireNilFields } from "@smartrr/shared/utils/RequireNilFields";
import { useEffect, useMemo, useState } from "react";

import { loadIntegrations } from "@vendor-app/app/_state/actionCreators/organization";
import { useActiveOrganizationSubSelector } from "@vendor-app/app/_state/reducers/organizations";
import { useSmartrrVendorDispatch, useSmartrrVendorSelector } from "@vendor-app/app/_state/typedVendorReduxHooks";

import { ICustomerEmailConfig, IEmailPreview } from "@smartrr/shared/entities/CustomerEmailConfig/types";
import { useSmartrrFlags } from "@smartrr/shared/LaunchDarkly";
import { SetupStoreAccess } from "@vendor-app/app/AdminRoute/AdminSetupRoute/libs/store";

export const useDefaultEmailConfig = (): RequireNilFields<ICustomerEmailConfig> => {
  const setup = SetupStoreAccess.useSetup()!;
  const orgShopifyData = useActiveOrganizationSubSelector(state => state?.shopifyStoreData);
  const { notificationEmailRefactor } = useSmartrrFlags(); // TODO: Remove when full refactor is complete
  const [emailConfig, setEmailConfig] = useState<RequireNilFields<ICustomerEmailConfig>>(
    createBasicDefaultEmailConfigPartial(orgShopifyData!, setup)
  );

  useEffect(() => {
    void (async () => {
      setEmailConfig(await createDefaultEmailConfig(orgShopifyData!, setup, notificationEmailRefactor));
    })();
  }, []);

  return emailConfig;
};

export const useApiKeys = () => {
  const [apiKeys, setApiKeys] = useState<{ [key in OrganizationIntegrationId]?: string }>({});
  const { activeOrganizationIntegrations: activeIntegrations, isLoadingIntegrations } = useSmartrrVendorSelector(
    state => state.vendorOrganizations
  );

  const dispatch = useSmartrrVendorDispatch();
  useEffect(() => {
    if (activeIntegrations) {
      const keys = activeIntegrations.reduce(
        (acc, integration) => {
          acc[integration.integrationId] = integration.apiKey;
          return acc;
        },
        {} as { [key in OrganizationIntegrationId]: string }
      );
      setApiKeys(keys);
    }
  }, [activeIntegrations]);

  useEffect(() => {
    dispatch(loadIntegrations());
  }, []);

  return { apiKeys, isLoadingIntegrations };
};

export const useEmailPreviewHTML = ({
  currBody,
  defaultsEnabled,
  disableHeaderAndFooter = false,
  manipulatedEmailConfigData,
}: {
  manipulatedEmailConfigData: IEmailPreview;
  defaultsEnabled: boolean;
  disableHeaderAndFooter: boolean | undefined;
  currBody: string;
}) => {
  const defaultEmailConfig = useDefaultEmailConfig();
  return useMemo(() => {
    if (!defaultsEnabled) {
      return currBody;
    }

    if (!disableHeaderAndFooter) {
      return (
        defaultEmailConfig.emailHeader! +
        defaultEmailConfig[manipulatedEmailConfigData.configValues?.body!] +
        defaultEmailConfig.emailFooter
      );
    }
    return String(defaultEmailConfig[manipulatedEmailConfigData.configValues?.body!]);
  }, [disableHeaderAndFooter, currBody, defaultEmailConfig, manipulatedEmailConfigData]);
};
